.wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.header {
    text-align: center;

    p {
        color: var(--blue_color);
        text-transform: uppercase;
        font-weight: 800;
        font-size: 1.5rem;
    }
}

.sponsor {
    scale: 0.85;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 9px;
}

.hint {
    background-color: #f7f7f7;
    border-radius: 10px;
    padding: 13px 15px;
    text-align: center;
    line-height: 20px;

    p {
        color: var(--black_color);
        font-weight: 400;
        font-size: 0.875rem;
    }
}

.coastList {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    justify-content: center;
}

.footer {
    div {
        margin: 0 auto;
    }
}

.selectPayment {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
