.tag-radio-button {
  height: 56px;
  display: flex;

  input {
    display: none;
  }

  label {
    border: 2px solid var(--lalau_color);
    color: var(--lalau_color);
    padding: 10px 30px;
    border-radius: 50px;
    font-size: 1.875rem;
    font-weight: 600;
    height: 100%;
    box-sizing: border-box;
  }

  input:checked + label {
    background-color: var(--lalau_color);
    color: var(--white_color);
    transition: .3s all;
  }
}