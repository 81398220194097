.setUserSiteLinkModal {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.header {
    display: flex;
    flex-direction: column;
    gap: 15px;

    h2 {
        font-weight: 800;
        font-size: 1.5rem;
        color: var(--light_black);
        text-align: center;
        text-transform: uppercase;
    }

    small {
        font-size: 1rem;
        line-height: 20px;
        text-align: center;
        color: var(--black_color);

        span {
            font-weight: bolder;
            color: var(--black_color);
        }
    }
}

.content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.controls {
    display: flex;
    gap: 5px;

    div {
        width: inherit !important;
        flex: 0.6 1;
        height: 50px;

        &:last-child {
            flex: 0.4 1;
        }
    }
}
